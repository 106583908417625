import * as React from "react"
import { graphql, Link } from "gatsby"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Identity from "../components/v2023/Identity/identity"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import Certifications from "../components/v2023/Certifications/certifications"

const getBrowserLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en"
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0]

    if (!lang) return "en"

    if (!["es", "en"].includes(lang)) {
        return "en"
    }

    return lang
}

const NotFoundPage = ({data, pageContext }) => {
    const lang = getBrowserLanguage()
    const page = data.pages.edges.filter((page) => page.node.locale === lang)[0].node

    pageContext.langKey = lang

    return <NewLayout pageContext={pageContext}>
        <Seo
            lang={lang}
            title={page.seo.title}
            description={page.seo.meta_description}
            image={page.seo?.image_2?.localFile?.publicURL}
        />

        <main className="main">
            <div className="main__section main__section--100 white-section brown">
                <div className="container" data-aos="zoom-in">
                    <StaticImage
                        src={"../new/resources/error/error.svg"}
                        alt="error"
                        placeholder="blurred"
                        title="error"
                    />
                    <h1>{page.title}</h1>
                    <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                    <Link to={page.button_url}>
                        <button className="button button--lg">{page.button}</button>
                    </Link>
                </div>
            </div>
            <Certifications />
            <Identity lang={lang} />
        </main>
    </NewLayout>
}

export default NotFoundPage

export const notFoundPageQuery = graphql`query {
    pages: allStrapi2022NotFoundPage {
        edges {
            node {
                seo {
                    title
                    meta_description
                    image_2 {
                        localFile {
                            publicURL

                        }
                    }
                }
                title
                description
                button
                button_url
                locale
            }
        }
    }
}`
